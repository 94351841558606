<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ row.status == 1 ? '有效' : '无效' }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { consoleJson, isNotNullObject } from '@/lib/util';
import { reqDelNews, reqNews } from '@/lib/request/auth2';

export default {
    name: 'informationList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'title' }],
            dataColumn: [
                { key: 'title', title: '标题', minWidth: 120 },
                { key: 'note', title: '内容', minWidth: 200, tooltip: true },
                { slot: 'status', title: '状态', minWidth: 120 },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            consoleJson(this.params);

            this.showAjaxLoading();
            reqNews(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(info) {
            let param = isNotNullObject(info) ? info : { create: 1 };
            this.goName('informationAdd', param);
        },
        //删除
        onDelete(info = {}) {
            let { id, title } = info;
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelNews({ id })
                        .then(() => {
                            this.fadeAlert('操作成功');
                            this.getList();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
    },
};
</script>
